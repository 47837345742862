import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../store/hooks"
import axios from "axios"
import { RPM } from "@vc/rpm-custom"
import { setCookieDoesntMatchFlag, setPlayer, setCanJumpIn, setRpmBearerToken, setRpmId, setAvatarId } from "../store/appUser"
import { setSelectedPlayer } from "../store/party"

type Props = {
    sendMessage: Function
}

export interface CSSPropertiesWithVars extends React.CSSProperties {
    '--gradient-color'?: string;
}

export const Profile: React.FC<Props> = ({sendMessage}) => {

    const { player, update, needsLoginFlag, cookieDoesntMatchFlag, rpmBearerToken, rpmId, avatarId } = useAppSelector(state  => state.appUser)
    const { className } = useAppSelector(state =>  state.scene)
    const [open, setOpen] = useState(false)
    const dispatch = useAppDispatch()
    
    const playerRef = useRef(player)
    const RPMConfig = {
        needsLoginFlag: needsLoginFlag,
        cookieDoesntMatchFlag: cookieDoesntMatchFlag,
        rpmBearerToken: rpmBearerToken,
        rpmId: rpmId,
        avatarId: avatarId,
        currentClassName: className,
        setCookieDoesntMatchFlag: setCookieDoesntMatchFlag,
        setPlayer: setPlayer,
        setCanJumpIn: setCanJumpIn,
        setSelectedPlayer: setSelectedPlayer,
        setRpmBearerToken: setRpmBearerToken,
        setRpmId: setRpmId,
        setAvatarId: setAvatarId,
        dispatch: dispatch,
    }

    const backPackConfig = {
        open:open,
        setOpen: setOpen,
        sendMessage: sendMessage
    }

    useEffect(() => {
        playerRef.current = player
    },[player])

    useEffect(() => {
        if(playerRef.current.glbUrl)
        loadImage(playerRef.current.glbUrl?.replace(".glb", ".png"))
    },[update])

    const loadImage = async (url: string) => {
        try {
          const response = await axios.get(url, {
            responseType: 'blob',
            headers: {
              'Cache-Control': 'no-cache'
            }
          });
      
          const imageUrl = URL.createObjectURL(response.data);
      
          const imgElement = document.getElementById("profile-image") as HTMLImageElement;
          if (imgElement) {
            imgElement.src = imageUrl;
          }

        } catch (error) {
          console.error('Error loading image:', error);
        }
    };

    return(
        <div>
            <button
                className="profile-button"
                onClick={() => setOpen(!open)}
            >
                <div className="image-container-online" style={
                {
                    '--gradient-color': "var(--primary-color)",
                    borderRadius: '50%', 
                    position: 'absolute', 
                    padding: '10px',
                    background: 'linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%)',
                    right: `16px`,
                    top: '16px',
                } as CSSPropertiesWithVars
            }>
            <div className="profile-inner-image-container">
                            <img 
                                className="profile-image"
                                id="profile-image"
                                alt=""
                            /> 
                            </div>
            
            </div>
            </button>
            <RPM
                player={player}
                config={RPMConfig}
                backpack={true}
                backPackConfig={backPackConfig}
                avatarBackgroundColour={'rgb(36, 30, 54)'}
                titles={false}
            />
        </div>
       
    )
}