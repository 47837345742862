import { Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import './App.css';
import "./CustomStyles/Chat.css";
import "./CustomStyles/Debug.css";
import "./CustomStyles/RPMStyles.css";
import { Fragment, useEffect } from "react";
import { InboundLinkRouting } from './components/InboundLinkRouting';
import WelcomePage from './components/LandingPage/WelcomePage';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { SearchPage } from './components/LandingPage/SearchPage';
import { LandingPage } from './components/LandingPage/LandingPage';
import { FAQPage } from './components/FAQ/FAQPage';
import { Footer } from './components/LandingPage/Footer';
import { AboutPage } from './components/About/AboutPage';
import { ScenePage } from './components/ScenePages/ScenePage';
import { setJWT } from './store/appUser';

const App: React.FC = () => {
  
  const {scenes} = useAppSelector(state => state.scene)
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is inactive');
      } 
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jwtToken = params.get("jwt");

    if (jwtToken) {
      dispatch(setJWT(jwtToken));

      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);
  
  return (
      <Fragment>
                <Routes> 
                    <Route
                      path="/"
                      element={
                          <LandingPage /> }
                    />
                    <Route
                      path="/home"
                      element={
                        <WelcomePage /> }
                    />
                    <Route
                      path="/:id"
                      element={
                        <WelcomePage /> }
                    />
                    <Route
                      path="/about"
                      element={
                      <><AboutPage /><Footer additionalClasses={'about-footer'} home={true}/></> }
                    />
                     <Route
                      path="/FAQ"
                      element={
                      <><FAQPage /><Footer additionalClasses={'faq-footer'} home={true} /></> }
                    />
                    {
                      scenes.map(scene => {
                        return <Route 
                                key={"/mv/live/"+scene.url}
                                path={`/mv/live/${scene.className}`}
                                element={
                                  <InboundLinkRouting scene={scene} scenePage={true} getProfile={false} rpmRefresh={false}/>
                          }
                        />
                      })
                    }
                    {
                      scenes.map(scene => {
                        return <Route
                                key={scene.url}
                                path={`${scene.url}`}
                                element={
                                  <InboundLinkRouting scene={scene} scenePage={false} getProfile={true} rpmRefresh={false}/>
                                }
                                />
                      })
                    }
                    {
                      scenes.map(scene => {
                        return <Route
                                key={scene.url + "/:id"}
                                path={`${scene.url}/:id`}
                                element={
                                  <InboundLinkRouting scene={scene} scenePage={false} getProfile={true} rpmRefresh={false}/>
                                }
                                />
                      })
                    }
                    {
                      scenes.map(scene => {
                        return <Route
                                key={scene.infoPageUrl}
                                path={`${scene.infoPageUrl}`}
                                element={
                                  <ScenePage scene={scene}/>
                                }
                                />
                      })
                    }
                    {
                      scenes.map(scene => {
                        return <Route
                                key={"/mv/rpm/" + scene.className}
                                path={"/mv/rpm/" + scene.className}
                                element={
                                  <InboundLinkRouting scene={scene} scenePage={false} getProfile={true}  rpmRefresh={true}/>
                                }
                                />
                      })
                    }
                    {/* <Route
                      path="/mv/rpm"
                      element={
                        <div style={{height: '100vh'}}>
                         
                        </div>
                      }
                    /> */}
                    <Route
                      path="/search"
                      element={
                        <SearchPage />
                      }
                      />
                </Routes>
        </Fragment>
  );
}

export default App;
